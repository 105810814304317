import { uniqueId } from './helpers'
import { SyncOutlined, CheckCircleOutlined, CloseCircleOutlined, HourglassOutlined } from '@ant-design/icons'
import { hideElaiMentions, defaultAvatarCode } from './config'
import { ONBOARDING_TEMPLATE_ID } from './constants'

const defaultAvatarConfigs = {
  'gia.casual': {
    code: 'gia.casual',
    name: 'Gia Casual',
    canvas: 'https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.png',
    gender: 'female',
    limit: 300,
  },
  'amanda.business': {
    code: 'amanda.business',
    name: 'Amanda Business',
    canvas: 'https://d3u63mhbhkevz8.cloudfront.net/common/amanda/amanda_business_low.png',
    gender: 'female',
  },
}

const defaultAvatar = defaultAvatarConfigs[defaultAvatarCode]

const videoStatuses = {
  draft: { title: 'Draft', color: '#31353b', icon: null },
  validating: { title: 'Validate', color: '#c971ff', icon: <SyncOutlined /> },
  rendering: { title: 'Rendering', color: '#c971ff', icon: <SyncOutlined spin /> },
  ready: { title: 'Ready', color: '#4868ff', icon: <CheckCircleOutlined /> },
  error: { title: 'Error', color: 'error', icon: <CloseCircleOutlined /> },
  moderation: { title: 'Moderation', color: 'warning', icon: <HourglassOutlined /> },
}

const blankSlide = () => ({
  id: uniqueId(),
  speech: hideElaiMentions ? '' : 'Welcome to Elai! Type your script and click "Render" to generate your first video!',
  avatar: defaultAvatar,
  language: 'English',
  voice: 'en-US-JennyNeural:cheerful',
  voiceProvider: 'azure',
  voiceType: 'text',
  animation: 'fade_in',
  canvas: {
    version: '4.4.0',
    background: '#ffffff',
    objects: [
      {
        type: 'avatar',
        left: 151.5,
        top: 36,
        width: 1080,
        height: 1080,
        fill: '#4868FF',
        scaleX: 0.3,
        scaleY: 0.3,
        src: defaultAvatar.canvas,
        avatarType: 'transparent',
        animation: {
          type: null,
          exitType: null,
        },
      },
    ],
  },
})

const getDefaultVoice = (voices, gender, language) => {
  const voiceObject = voices[language ? voices.findIndex((l) => l.name === language) : 0][gender][0]

  return {
    voice: voiceObject.defaultStyle ? `${voiceObject.voice}:${voiceObject.defaultStyle}` : voiceObject.voice,
    voiceProvider: voiceObject.voiceProvider,
  }
}

// duration should be a number of seconds
const formatSeconds = (secDuration, fullText) => {
  if (!secDuration) return ''
  const minutesDesc = fullText ? ' minutes' : 'm'
  const secondsDesc = fullText ? ' seconds' : 's'

  const minutes = Math.floor(secDuration / 60)
  const seconds = Math.round(secDuration - minutes * 60)

  const minutesText = minutes > 0 ? `${minutes}${minutesDesc}` : ''
  const secondsText = seconds > 0 ? `${seconds}${secondsDesc}` : ''

  return secDuration < 60 ? `${secondsText}`.trim() : `${minutesText} ${secondsText}`.trim()
}

// duration should be a number of minutes
const formatMinutes = (minutesDuration, fullText = false) => {
  if (!minutesDuration) return ''
  const minutesDesc = fullText ? ' minutes' : 'm'
  const hoursDesc = fullText ? ' hours' : 'h'

  const hours = Math.floor(minutesDuration / 60)
  const minutes = Math.round(minutesDuration - hours * 60)
  const hoursText = hours > 0 ? `${hours}${hoursDesc}` : ''
  const minutesText = minutes > 0 ? `${minutes}${minutesDesc}` : ''

  return minutesDuration < 60 ? `${minutesText}`.trim() : `${hoursText} ${minutesText}`.trim()
}

// if slide has no canvas it means it was pulled in a list from backend. in this case it should have hasAvatar marking
const hasAvatar = (slide) =>
  'hasAvatar' in slide
    ? slide.hasAvatar
    : slide.canvas?.objects?.some((obj) => obj.type === 'avatar' && obj.avatarType !== 'voiceover')

const getRenderingTime = (video) => {
  let minutesLeft = 0
  let total = 0

  const minutesFromLaunch = (new Date() - new Date(video.data.renderStartedAt)) / 60000
  const minutesFromLastUpdate = (new Date() - new Date(video.updatedAt)) / 60000
  const waitingTime = video.data.waitingTime

  // calc longest slides to be rendered one after another, considering that simuntaneusly we will render on all AVAILABLE SERVERS
  const slidesToRenderAvatars = { total: 0, duration: 0 }
  const slidesToRender = { total: 0, duration: 0, heavyDuration: 0 }
  let ifRenderStarted = minutesFromLaunch > waitingTime
  let ifAllSlidesReady = true
  let avatarsType

  const is4k = video.data?.resolution === '4K'

  video.slides?.forEach((slide) => {
    const duration = slide.duration || slide.approxDuration || 15
    // data for slides rendering
    if (slide.status !== 'ready') {
      if (
        slide.canvas?.objects?.length > (is4k ? 55 : 100) ||
        slide.canvas?.objects?.filter((o) => o.animation).length > 30
      ) {
        slidesToRender.heavyDuration += duration
      } else {
        slidesToRender.total++
        slidesToRender.duration += duration
      }
    }

    // data for avatar rendering
    if (hasAvatar(slide)) {
      if (slide.status !== 'ready') ifAllSlidesReady = false

      // if slide avatar is ready we dont add to slidesToRenderAvatars
      if (!['edited', 'audioReady', 'renderingAvatar'].includes(slide.status)) {
        ifRenderStarted = true
        return
      }

      slidesToRenderAvatars.total++
      slidesToRenderAvatars.duration += duration
      if (!avatarsType && ['mascot', 'photo'].includes(slide.avatar?.type)) avatarsType = slide.avatar.type
    }
  })

  // if there are no slides with avatars we consider that render started
  if (!slidesToRenderAvatars.total) ifRenderStarted = true

  /**
   * 1. Waiting time - add only if no rendering started or passed less minutes than waitingTime
   */
  if (waitingTime) {
    if (ifRenderStarted) {
      total += waitingTime
    } else {
      total += waitingTime
      minutesLeft += waitingTime - minutesFromLaunch
    }
  }

  /* 2. renderingAvatars
   * total: avatars rendering time
   * left: current time() - (waitingTime + renderStarted + total)
   */
  if (slidesToRenderAvatars.total) {
    // avatar render coef x1.5/60 (0.025), 12 servers
    // photo avatar coef x15/60 (0.25), 3 servers
    // mascot avatar coef x6/60 (0.08), 1 server
    let coef = avatarsType === 'mascot' ? 0.08 : avatarsType === 'photo' ? 0.25 : 0.025
    let servers = avatarsType === 'mascot' ? 1 : avatarsType === 'photo' ? 3 : 12
    // time to render avatar and 10 sec (0.17m) for each task delay (in case of queue and other reasons)
    const renderingQueueRenderTime =
      (slidesToRenderAvatars.duration * coef) / Math.min(slidesToRenderAvatars.total, servers) +
      slidesToRenderAvatars.total * 0.17
    total += renderingQueueRenderTime
    if (ifRenderStarted) {
      minutesLeft += Math.max(
        minutesFromLaunch > waitingTime
          ? renderingQueueRenderTime - (minutesFromLaunch - waitingTime)
          : renderingQueueRenderTime - minutesFromLastUpdate,
        0,
      )
    } else minutesLeft += renderingQueueRenderTime
  }

  /* 3. renderingSlides - time to render all slides that are not ready
   * heavy: only 1 at a time. coef 5
   * 4K: 3x at a time. coef 0.9 (1.2 with delays)
   * reg: 9x at a time (7 including queue). coef 0.5 (0.8 with delays)
   * add 10 seconds (0.17m) for each task in case of queues etc
   * transcribing is ignored as it takes around 1 second
   */
  const renderingTimeForRegularSlides = slidesToRender.total.length
    ? (slidesToRender.duration * (is4k ? 1.2 : 0.8)) / Math.min(slidesToRender.total, is4k ? 3 : 7) / 60 +
      slidesToRender.total * 0.17
    : 0
  const renderingTimeForHeavyTasks = (slidesToRender.heavyDuration * 5) / 60 || 0
  minutesLeft += renderingTimeForHeavyTasks + renderingTimeForRegularSlides
  total += renderingTimeForHeavyTasks + renderingTimeForRegularSlides

  /**
   * 4. Final render. Speed 0.5x, 0.9x for 4K
   */
  // for 1 slide video we just upload slide mp4 file
  let finalRender = 0.2
  if (video.slides.length > 1) {
    const duration = video.slides.reduce((dur, slide) => dur + (slide.duration || slide.approxDuration || 15), 0) / 60
    const transitions = video.slides.filter((s) => s.transition).length
    finalRender = (duration / ((8 * 0.6) / Math.max(1, Math.log(transitions)))) * (is4k ? 1.1 : 0.5)
  }

  total += finalRender
  minutesLeft += ifAllSlidesReady ? finalRender - minutesFromLastUpdate : finalRender

  // console.log({
  //   minutesLeft,
  //   total,
  //   ifRenderStarted,
  //   minutesFromLaunch,
  //   slidesToRenderAvatars,
  //   slidesToRender,
  //   renderingTimeForRegularSlides,
  //   renderingTimeForHeavyTasks,
  //   finalRender,
  // })

  if (minutesLeft < 0) minutesLeft = 0.1

  return {
    minutesLeft,
    minutesLeftFormatted: formatMinutes(minutesLeft) || '1m',
    percent: 100 - (minutesLeft / total) * 100,
  }
}

const syncSlideItemsWithOriginState = (video) => {
  if (video.slides)
    video.slides.forEach(
      (s) =>
        s.canvas?.objects &&
        s.canvas.objects.forEach((o) => {
          if (!o.visible && o.type !== 'avatar') o.visible = true

          if (o.originState) {
            Object.assign(o, o.originState)
            delete o.originState
          }
        }),
    )
  return video
}

const isTemplateApplicable = (t) => t.template.enabled && !t.template.blank && t._id !== ONBOARDING_TEMPLATE_ID

const checkRenderedVideoIsInteractive = ({ playerData }) => playerData?.slides?.some(({ objects }) => objects?.length)

export {
  blankSlide,
  getDefaultVoice,
  videoStatuses,
  getRenderingTime,
  formatSeconds,
  syncSlideItemsWithOriginState,
  isTemplateApplicable,
  checkRenderedVideoIsInteractive,
}
