/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, forwardRef, useImperativeHandle, useCallback, useRef } from 'react'
import { Spin } from 'antd'
import { fabric } from 'fabric'
import {
  removeHandler,
  copyHandler,
  pasteHandler,
  createCanvasObject,
  changeCanvasActiveObject,
  deleteObjectsOutsideCanvas,
  checkObjectsAnimationTime,
  modifyBackground,
  manageLayersSelection,
  removeNonUniformScalingControls,
  scaleTextboxObjects,
  textTypes,
  MAX_OBJ_SCALE,
  groupObjects,
  ungroupObjects,
  hasObjectNestedObjectTypes,
} from '../../../../utils/canvas/canvas'
import { removeLines, snapObjects } from './canvasSnapping'
import { animateCanvasObjects, checkObjectHasAnimation, prepareAnimation, SHIFT_DURATION } from './animations/animation'
import {
  playVideos,
  seekVideos,
  pauseVideos,
  syncVideoState,
  syncVideosWithDuration,
  resetVideosToStart,
} from './videos'
import { useStore } from '../../../../store'
import './canvas.less'
import Icon from '../../../../components/Icon'
import ContextMenu from './components/contextMenu'
import InteractivityEmulationControls from './components/interactivityEmulationControls'
import {
  isDebug,
  PROPS_TO_INCLUDE,
  MAX_TEXT_HEIGHT,
  MAX_TEXT_WIDTH,
  style,
  listenForShiftPressed,
  DEFAULT_SLIDE_DURATION,
} from './constans'
import { isTesting } from '../../../../utils/config'
import { keyMap } from '../../constants'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import { useSlideDuration } from '../../../../hooks/useSlideDuration'
import { toggleCanvasVisibility } from './helpers'

let updateTimeout

const Canvas = forwardRef(
  (
    {
      data,
      video,
      player,
      // for accessing actual state from outside react context
      playerRef,
      setPlayer,
      canvasRegistry,
      updateSlide,
      activeSlide,
      canvasActiveObject,
      setCanvasActiveObject,
      activeObjectModifier,
      setActiveObjectModifier,
      activeSidebarTab,
      setActiveSidebarTab,
      canvasesContainerRef,
      playNextSlide,
      interactiveQuestion,
    },
    ref,
  ) => {
    const notification = useElaiNotification()
    const isAvatarSelected = useRef(false)
    const isShiftPressed = useRef(null)
    const isBackgroundModified = useRef(false)

    const [canvas, setCanvas] = useState()
    const [isTextEditingEntered, setIsTextEditingEntered] = useState(false)
    const [preparingAnimations, setPreparingAnimations] = useState(false)
    const [interactiveElements, setInteractiveElements] = useState(null)

    const voices = useStore((stores) => stores.voicesStore.voices)
    const avatarsData = useStore((stores) => stores.videosStore.avatars)
    const setVisible = useStore((stores) => stores.colorEditorStore.setVisible)
    const setOnShowCallback = useStore((stores) => stores.colorEditorStore.setOnShowCallback)

    const { getApproxDuration } = useSlideDuration(data)

    useImperativeHandle(ref, () => ({
      /**
       * Ref to update physical canvas on external state change
       */
      async updateCanvasFromState(changes, activeObject = null) {
        if (isDebug) {
          console.log('updateCanvasFromState')
        }
        if (!canvas) return
        setPlayer((p) => Object.assign({}, p, { canvasReady: false }))
        const isBgMode = canvas.bgMode
        if (isBgMode) canvas.bgMode = false
        await canvas.loadFromJSON(changes).then(() => {
          if (activeObject?.bg) canvas.switchToBackgroundMode()
          else if (isBgMode) canvas.switchToElementsMode()
          canvasRegistry.updateThumbnail({ id: data.id, canvas: changes })
        })
        setPlayer((p) => Object.assign({}, p, { canvasReady: true }))
        if (activeObject) {
          activeObject = canvas.getObjects().find((obj) => obj.id === activeObject.id)
          if (activeObject) canvas.setActiveObject(activeObject).renderAll()
          else setCanvasActiveObject(null)
        }
      },

      getActiveObject() {
        return canvas?.getActiveObject()
      },

      changeCanvasBackground(color) {
        if (!canvas) return
        canvas.setBackgroundColor(color, () => canvas.renderAll())
      },

      onChangeSlideTime(time) {
        if (player.status === 'playing') playVideos(canvas, time)
        else if (player.status === 'idle') {
          seekVideos(canvas, data.duration, time)
          animateCanvasObjects(canvas, player)
        }
      },

      requestObjectsVisibilityUpdate() {
        if (canvas) applyObjectsVisibilityChanges()
      },
    }))

    useEffect(() => {
      canvasRegistry.toggleCanvasesReadOnly(player.activePreview)
    }, [player.activePreview])

    const resetActiveTabToDefault = () => setActiveSidebarTab('elements')

    const updateCanvas = useCallback(
      ({ linkedSlideChanges = null } = {}) => {
        if (!canvas) return
        console.log('updateCanvas')

        const time = performance.now()
        const updatedCanvas = canvas.toObject(PROPS_TO_INCLUDE)
        updateSlide({ ...linkedSlideChanges, canvas: updatedCanvas }, { slideId: data.id })
        canvasRegistry.updateThumbnail({ id: data.id, canvas: updatedCanvas })
        if (isTesting) localStorage.setItem('__slideUpdated', performance.now() - time)
      },
      [canvas, activeSlide],
    )

    const moveObjectsWithKeys = (e) => {
      const ao = canvas.getActiveObject()
      if (ao) {
        e.preventDefault()
        switch (e.code) {
          case 'ArrowUp': {
            ao.set('top', ao.top - 1)
            break
          }
          case 'ArrowDown': {
            ao.set('top', ao.top + 1)
            break
          }
          case 'ArrowLeft': {
            ao.set('left', ao.left - 1)
            break
          }
          case 'ArrowRight': {
            ao.set('left', ao.left + 1)
            break
          }
          default: {
            return
          }
        }
        canvas.renderAll()
      }
    }

    /**
     * For unselect active object when start showing color editor for background
     */
    useEffect(() => {
      setOnShowCallback((isBackground) => {
        if (isBackground) {
          setCanvasActiveObject(null)
          canvas?.discardActiveObject().requestRenderAll()
        }
      })
    }, [canvas, data, activeSlide])

    const getSlideChangesOnPasteAvatar = useCallback(
      (copiedObject) => {
        const { code, gender, type, limit, accountId, canvas } = copiedObject.avatarBundle
        const linkedSlideChanges = {
          avatar: { code, gender, type, limit, accountId, canvas },
          status: data.status,
        }
        const avatarGender = copiedObject.avatarBundle.gender
        if (data.avatar.code !== copiedObject.avatarBundle.code) linkedSlideChanges.status = 'edited'
        if (data.avatar.gender !== avatarGender) {
          linkedSlideChanges.voice = copiedObject.voice.id
          linkedSlideChanges.voiceProvider = copiedObject.voice.provider
        }
        if (copiedObject.clonedCanvasObject.avatarType === 'voiceover') {
          linkedSlideChanges.status = 'edited'
          linkedSlideChanges.voiceType = data.voiceType !== 'silence' ? data.voiceType : 'text'
        }
        return linkedSlideChanges
      },
      [avatarsData, data, voices],
    )

    const handleKeyUp = (event) => {
      // Arrow keys
      if (event.keyCode >= 37 && event.keyCode <= 40) {
        const activeObject = canvas.getActiveObject()
        if (activeObject) {
          updateCanvas()
        }
      }
    }

    /**
     * COPYPASTE
     */
    useEffect(() => {
      const keysHandler = async (e) => {
        const tagName = e.target.tagName?.toLowerCase()
        if (tagName !== 'textarea' && (tagName !== 'input' || e.target.type === 'radio')) {
          if (e.metaKey || e.ctrlKey) {
            if (e.code === 'KeyC') {
              const { id: slideId, avatar, voice, voiceProvider } = data
              await copyHandler({ slideId, canvas, avatar, voice, voiceProvider })
            } else if (e.code === 'KeyV') {
              if (!isTextEditingEntered)
                await pasteHandler(
                  { slideId: data.id, canvas, speech: data.speech },
                  canvas,
                  updateCanvas,
                  getSlideChangesOnPasteAvatar,
                )
            } else if (e.code === 'KeyG' && e.shiftKey) {
              e.preventDefault()
              if (canvas.getActiveObject().type !== 'group') return
              ungroupObjects(canvas)
              updateCanvas()
            } else if (e.code === 'KeyG') {
              e.preventDefault()
              if (canvas.getActiveObject().type === 'group') return
              groupObjects(canvas)
              updateCanvas()
            }
          } else if (e.keyCode === keyMap.BACKSPACE || e.keyCode === keyMap.DELETE) {
            if (!canvas?.getActiveObject()) return
            removeHandler(canvas)
            canvas.renderAll()
            updateCanvas()
          } else if (e.code.includes('Arrow')) {
            moveObjectsWithKeys(e)
          }
        }
      }

      document.addEventListener('keydown', keysHandler)
      document.addEventListener('keyup', handleKeyUp)
      return () => {
        document.removeEventListener('keydown', keysHandler)
        document.removeEventListener('keyup', handleKeyUp)
      }
    }, [data, canvas, isTextEditingEntered, getSlideChangesOnPasteAvatar])

    /**
     * COPYPASTE: Clears object clipboard when editing text
     */
    useEffect(() => {
      if (canvasActiveObject && canvasActiveObject.text) {
        canvasActiveObject.on('editing:entered', () => setIsTextEditingEntered(true))
        canvasActiveObject.on('editing:exited', () => setIsTextEditingEntered(false))
      }
    }, [canvasActiveObject])

    const handleClickOutsideCanvas = (event) => {
      if (
        canvas?.bgMode &&
        canvasesContainerRef.current &&
        !canvasesContainerRef.current.contains(event.target) &&
        !event.target.closest('#elements-tab')
      ) {
        switchToElementsMode()
      }
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutsideCanvas)
      return () => document.removeEventListener('mousedown', handleClickOutsideCanvas)
    }, [canvas, canvasesContainerRef])

    const makeObjectsVisible = () => {
      const objects = canvas.getObjects()
      objects.forEach((obj) => {
        if (obj.type === 'avatar' || obj.bg) return
        obj.set({ visible: true })
      })
    }

    const applyObjectsVisibilityChanges = async (forceUpdate) => {
      let { objectsVisibilityMode } = player
      const currentTime = Math.round(player.currentTime * 10) / 10

      if (canvas.activeLoading) await canvas.activeLoading
      if (!forceUpdate) setTimeout(() => applyObjectsVisibilityChanges(true), 100)

      if (objectsVisibilityMode === 'all' && player.linkedCanvasObject) changeLinkedCanvasObject(null)
      else if (objectsVisibilityMode !== 'all' && canvas.getActiveObject() && !player.linkedCanvasObject)
        changeLinkedCanvasObject(canvas.getActiveObject())

      const duration = data.duration || getApproxDuration(data) || DEFAULT_SLIDE_DURATION
      canvas.getObjects().forEach((obj) => {
        if (obj.type === 'avatar' || obj.bg) return

        if (
          objectsVisibilityMode === 'all' ||
          !obj.animation ||
          (obj.animation.startTime === 0 && !obj.animation.endTime)
        ) {
          return obj.set({ visible: true })
        }

        let {
          animation: { startTime, endTime },
        } = obj
        if (typeof startTime !== 'number' && obj.animation.startMarker) startTime = 0
        if (typeof endTime !== 'number' && obj.animation.endMarker) endTime = duration
        if (obj.type === 'question') startTime = currentTime ? duration - SHIFT_DURATION : 0

        const isVisible =
          (!startTime || currentTime >= startTime) &&
          (!endTime || currentTime <= (endTime < 0 ? duration + endTime : endTime))
        obj.set({ visible: isVisible })
        if (!isVisible && canvas.getActiveObject() === obj) {
          canvas.discardActiveObject()
        }
      })
      canvas.renderAllSafe()
    }

    useEffect(() => {
      if (canvas && player.status === 'idle') applyObjectsVisibilityChanges()
    }, [canvas, player.status, player.currentTime, player.objectsVisibilityMode])

    const switchToBackgroundMode = () => {
      isBackgroundModified.current = false
      canvas.switchToBackgroundMode()
    }

    const switchToElementsMode = () => {
      if (isBackgroundModified.current) updateCanvas()
      isBackgroundModified.current = false
      canvas.switchToElementsMode()
    }

    const changeLinkedCanvasObject = (target) => {
      setPlayer((player) => {
        if (
          player.objectsVisibilityMode !== 'all' &&
          target &&
          !target.bg &&
          !target._objects &&
          checkObjectHasAnimation(target)
        )
          return { ...player, linkedCanvasObject: target }
        return { ...player, linkedCanvasObject: null }
      })
    }

    const initializeCanvasEvents = (canvas) => {
      canvas.__eventListeners = {}

      canvas.on('object:modified', (e) => {
        const { target } = e
        if (isDebug) console.log('object:modified')
        if (target.type === 'i-text' && target.scaleX !== target.scaleY) {
          target.set({ scaleY: target.scaleX })
        }
        target._objects?.forEach((obj) => {
          if (obj.type === 'i-text') obj.set({ scaleX: target.scaleX, scaleY: target.scaleX })
        })

        if (e.action === 'scale') scaleTextboxObjects(target, canvas)

        //to not update state when only text is changed, because state was updated on text:changed event
        if (!target.text || e.action) {
          updateCanvas()
        } else isBackgroundModified.current = true
      })
      canvas.on('selection:created', () => {
        if (isDebug) console.log('selection:created')
        const target = removeNonUniformScalingControls(canvas.getActiveObject())
        isAvatarSelected.current = target?.type === 'avatar'
        setCanvasActiveObject(target)
        changeLinkedCanvasObject(target)
      })
      canvas.on('selection:cleared', () => {
        if (isDebug) console.log('selection:cleared')
        setCanvasActiveObject((ao) => {
          if (ao?.selectionStyles?.length) ao.selectionStyles = []
          if (canvas.bgMode) {
            canvas.setActiveObject(ao)
            return ao
          }
          return null
        })
        changeLinkedCanvasObject(null)
      })
      canvas.on('selection:updated', () => {
        if (isDebug) console.log('selection:updated')
        const target = removeNonUniformScalingControls(canvas.getActiveObject())
        setCanvasActiveObject(target)
        changeLinkedCanvasObject(target)
      })
      canvas.on('mouse:down', () => {
        setVisible(false)
      })
      canvas.on('mouse:dblclick', ({ target }) => {
        const canvasContainsBgObj = canvas.getObjects().some((obj) => obj.bg)
        if (canvas.bgMode) {
          switchToElementsMode()
        } else if ((!target || target.bg) && canvasContainsBgObj && !canvas.played) {
          switchToBackgroundMode()
        }
      })
      canvas.on('object:moving', (e) => {
        snapObjects(e, canvas, isShiftPressed.current)
      })
      canvas.on('object:moved', (e) => {
        removeLines(canvas)
        if (!canvas.bgMode) deleteObjectsOutsideCanvas(e.target, canvas, updateCanvas)
      })
      canvas.on('mouse:over', (e) => {
        if (!e.target?.bg) return
        if (canvas.bgMode) e.target.set({ hoverCursor: 'move' })
        else e.target.set({ hoverCursor: 'default' })
      })
      canvas.on('text:selection:changed', ({ target }) => {
        target.set({ selectionStyles: target.getSelectionStyles(target.selectionStart, target.selectionEnd) })
        setCanvasActiveObject(target)
      })
      canvas.on('text:changed', (v) => {
        const { target } = v
        target.text = target.text.replaceAll('\t', '')

        if (target.scaleX * target.width > MAX_TEXT_WIDTH) {
          const scale = MAX_TEXT_WIDTH / target.width
          target.set({ scaleX: scale, scaleY: scale })
        }
        if (target.scaleY * target.height > MAX_TEXT_HEIGHT) {
          const scale = MAX_TEXT_HEIGHT / target.height
          target.set({ scaleX: scale, scaleY: scale })
        }

        clearTimeout(updateTimeout)

        updateTimeout = setTimeout(() => {
          updateCanvas()
        }, 500)
      })

      canvas.on('object:scaling', ({ target, transform }) => {
        if (textTypes.includes(target.type)) {
          if (target.scaleX * target.width > MAX_TEXT_WIDTH) target.set({ scaleX: MAX_TEXT_WIDTH / target.width })
          if (target.scaleY * target.height > MAX_TEXT_HEIGHT) target.set({ scaleY: MAX_TEXT_HEIGHT / target.height })
        } else {
          if (target.scaleX > MAX_OBJ_SCALE) target.set({ scaleX: MAX_OBJ_SCALE })
          if (target.scaleY > MAX_OBJ_SCALE) target.set({ scaleY: MAX_OBJ_SCALE })
        }

        /**
         * This is required for adjusting border radius when scaling rectangles
         */
        if (target.type === 'rect' && transform.action !== 'scale')
          target.set({
            width: target.width * target.scaleX,
            height: target.height * target.scaleY,
            scaleX: 1,
            scaleY: 1,
            objectCaching: false,
          })

        /**
         * Prevent unpropotional scaling of text
         */
        if (target.type === 'i-text' && target.scaleX !== target.scaleY) {
          target.set({ scaleY: target.scaleX })
        }
        if (hasObjectNestedObjectTypes(target, textTypes)) {
          target.set({ scaleY: target.scaleX })
        }
      })

      canvas.on('object:removed', ({ target }) => {
        if (isDebug) console.log('object(s):removed')
        if (target.type === 'group') target.stop()
      })
    }

    /**
     * TODO: only in this case all events work fine
     */
    useEffect(() => {
      if (!canvas) return
      resetVideosToStart()
      initializeCanvasEvents(canvas)
      if (player.activePreview) setPlayer((p) => ({ ...p, status: 'loading', canvasReady: true }))
    }, [canvas])

    /**
     * For drop needRecalculateDims flag on after recreate from Backend (video translation)
     * correctly updated only if canvas.objects.length in dependencies
     */
    // TODO maybe move to Question.initialize or somewhere else, but canvas after-events breaks normal render of object
    useEffect(() => {
      if (!canvas?._objects?.length) return
      const questionObj = canvas._objects.find((obj) => obj.type === 'question' && obj.meta?.needRecalculateDims)
      if (!questionObj) return
      const timer = setTimeout(() => {
        questionObj.meta.needRecalculateDims = undefined
        updateCanvas()
      }, 500)
      return () => clearTimeout(timer)
    }, [canvas?._objects?.length])

    /**
     * Init canvas for each new slide. should run only when slide changes
     */
    useEffect(() => {
      if (canvasRegistry.ready || (canvasRegistry.readyInitialSlide && canvasRegistry.readyInitialSlide === data.id)) {
        const targetCanvas = canvasRegistry.selectCanvas(data)
        // only for already preloaded initial slide
        if (targetCanvas === false) return

        if (isDebug) console.log('INIT CANVAS')
        // NOTE: for debugging purposes
        if (!targetCanvas) console.error('Canvas not found', { video: video._id, slide: data.id })
        setPlayer((p) => ({ ...p, canvasReady: false }))

        setCanvas(targetCanvas)
        if (!player.activePreview) toggleCanvasVisibility(targetCanvas, true)

        return listenForShiftPressed(isShiftPressed)
      }
    }, [data.id, activeSlide, canvasRegistry.ready, canvasRegistry.readyInitialSlide])

    /**
     * useEffect expects a function or undefined to be returned in order to perform cleanup afterwards
     * If something different is returned, it cannot perform the cleanup, and the gc can also fail.
     */
    const handleChangesOutsideCanvas = useCallback(async () => {
      if (!activeObjectModifier || !canvas) return

      if (activeObjectModifier === 'discardActiveObject') {
        canvas.discardActiveObject().renderAll()
        setCanvasActiveObject(null)
        return setActiveObjectModifier(null)
      }

      if (activeObjectModifier.change === 'layersSelection') {
        manageLayersSelection(activeObjectModifier.value, canvas, canvasActiveObject, isShiftPressed.current)
        canvas.renderAllSafe()
        return setActiveObjectModifier(null)
      }

      setPlayer((p) => ({ ...p, canvasReady: false }))
      await applyActiveObjectModifierToCanvas(activeObjectModifier, canvas)
      setPlayer((p) => ({ ...p, canvasReady: true }))
      canvas.renderAllSafe()
      updateCanvas({ linkedSlideChanges: activeObjectModifier.linkedSlideChanges })
      setActiveObjectModifier(null)
    }, [activeObjectModifier, canvas])

    /**
     * Handles changes to active object or adding new objects outside of canvas (used mostly in sidebar)
     */
    useEffect(() => {
      handleChangesOutsideCanvas()
    }, [handleChangesOutsideCanvas])

    const applyActiveObjectModifierToCanvas = async (activeObjectModifier, canvas) => {
      if (playerRef.current?.status !== 'idle' && activeObjectModifier.newObject) return resetActiveTabToDefault()
      try {
        if (activeObjectModifier.newObject) {
          await createCanvasObject(activeObjectModifier, canvas)
          if (activeObjectModifier.newObject === 'avatar_listener') {
            resetActiveTabToDefault()
          }
        } else if (activeObjectModifier.change) {
          if (canvasActiveObject.type === 'activeSelection') {
            if (['groupedAlignment', 'textType'].includes(activeObjectModifier.change)) {
              await changeCanvasActiveObject(activeObjectModifier, canvas, canvasActiveObject)
            } else {
              canvasActiveObject.forEachObject(
                async (obj) => await changeCanvasActiveObject(activeObjectModifier, canvas, obj),
              )
            }
          } else {
            await changeCanvasActiveObject(activeObjectModifier, canvas, canvasActiveObject)
            if (canvasActiveObject.type === 'video')
              syncVideoState(canvas, canvasActiveObject, activeObjectModifier.changedFields, data.duration)
          }
        } else if (activeObjectModifier.background) {
          modifyBackground(canvas, canvasActiveObject, activeObjectModifier)
        }
        if (activeObjectModifier.change === 'animation') {
          changeLinkedCanvasObject(canvasActiveObject)
        }
      } catch (e) {
        if (typeof e === 'boolean') {
          notification.error({
            message: 'Cannot load media. Please contact support.',
          })
        } else {
          const err = e.toString()
          notification.error({
            message: err === '[object Event]' ? 'Unknown error occured. Please contact support.' : err,
          })
        }
      }
    }

    /**
     * Manage canvas active object on activeSidebarTab changes,
     * canvasReady dependency to set active avatar object when canvas is loaded
     */
    useEffect(() => {
      if (!player.canvasReady || !canvas) return
      if (activeSidebarTab === 'presenter') {
        const avatarObj = canvas.getObjects().find((obj) => obj.type === 'avatar')
        if (avatarObj) canvas.setActiveObject(avatarObj).requestRenderAll()
        if (isTesting && localStorage.getItem('__activeObjectInit'))
          localStorage.setItem('__activeObject', performance.now() - Number(localStorage.getItem('__activeObjectInit')))
      } else {
        if (
          (activeSidebarTab === 'elements' &&
            (canvasActiveObject?.type !== 'avatar' || canvasActiveObject?.type === 'activeSelection')) ||
          ['text', 'interactivity', 'uploads', 'record'].includes(activeSidebarTab) ||
          isAvatarSelected.current
        )
          return
        canvas.discardActiveObject().renderAll()
        setCanvasActiveObject(null)
      }
    }, [activeSidebarTab, player.canvasReady])

    /**
     * Launch all animations when canvas toggled to ready
     */
    useEffect(() => {
      if (canvas && player.canvasReady) canvas.startAnimations()
    }, [canvas, player.canvasReady])

    const prepareCanvasForAnimation = useCallback(async () => {
      if (player.status === 'loading') {
        // only to hide canvas objects at the beginning of each slide in video preview
        setPreparingAnimations(true)
      }
      if (player.status === 'playing') {
        setPlayer((p) => ({ ...p, canvasReady: false }))
        // wait for canvas to load
        if (canvas.activeLoading) await canvas.activeLoading
        // reload canvas from slide state to update canvas with markers time if needed
        if (checkObjectsAnimationTime(canvas.getObjects())) await canvas.loadFromJSON(data.canvas)
        makeObjectsVisible()
        getInteractiveElements()
        prepareAnimation(canvas, data.canvas, data.animation, data.exitAnimation, data.duration)

        setPreparingAnimations(false)
        // NOTE: try to fix canvas animation lag at the beginning
        fabric.util.requestAnimFrame(() => animateCanvasObjects(canvas, player))

        canvas.startAnimations()
        syncVideosWithDuration(canvas, data.duration || getApproxDuration(data))
        await playVideos(canvas, player.currentTime)
        setPlayer((p) => ({ ...p, canvasReady: true }))
        canvas.played = true

        // If it's a preview, we should make the canvas visible only when it's completely ready
        // and has initialized animations to prevent blinking
        if (player.activePreview) {
          canvasRegistry.hideCanvases()
          toggleCanvasVisibility(canvas, true)
        } else resetActiveTabToDefault()
      }
      if (canvas && player.status === 'idle') {
        pauseVideos(canvas)

        const ao = canvas.getActiveObject()

        if (!canvas.initialized) setPlayer((p) => ({ ...p, canvasReady: false }))
        if (canvas.activeLoading) await canvas.activeLoading

        if (canvas.played || !canvas.initialized) {
          // it's necessary to correctly sync the obj.visible property after playback to prevent blinking or flickering effects
          applyObjectsVisibilityChanges()
          // and then pass this state to next canvas reloading
          await canvas.loadFromJSON(data.canvas, player.activePreview ? null : canvas.getObjects())
        }
        canvas.startAnimations()
        setPlayer((p) => ({ ...p, canvasReady: true }))
        if (ao && canvas.played) {
          canvas.forEachObject((obj) => {
            if (obj.id === ao.id) {
              canvas.setActiveObject(obj).renderAll()
            }
          })
        }
        canvas.played = false
      }
    }, [canvas, player.status])

    const getInteractiveElements = () => {
      if (!player.activePreview || !player.canvasReady) return
      const elements = canvas
        .getObjects()
        .filter((obj) => obj.meta?.interactivity)
        .map((obj) => {
          const props = { id: obj.id, type: obj.type, interactivity: obj.meta?.interactivity }
          if (textTypes.includes(obj.type)) props.text = obj.text
          else props.src = obj.toDataURL({ format: 'png', quality: 0.1 })
          return props
        })
      setInteractiveElements(elements)
    }

    /**
     * Prepares canvas objects for animation, returns the canvas to the main state after animation
     */
    useEffect(() => {
      prepareCanvasForAnimation()
    }, [prepareCanvasForAnimation])

    /**
     * Plays animation
     */
    useEffect(() => {
      let animationId
      if (player.status === 'playing') {
        animationId = fabric.util.requestAnimFrame(() => animateCanvasObjects(canvas, player))
      }
      return () => animationId && cancelAnimationFrame(animationId)
    }, [player.status, player.currentTime])

    /**
     * Set player to start if animation is changed
     */
    useEffect(() => {
      if (player.currentTime !== 0) setPlayer((p) => ({ ...p, status: 'idle', currentTime: 0 }))
    }, [data.animation])

    /**
     * Prevent scrolling page if canvas question object is scrolled
     */
    useEffect(() => {
      if (canvasesContainerRef.current) {
        const handleWheel = (e) => e.preventDefault()
        canvasesContainerRef.current.addEventListener('wheel', handleWheel)
        return () => canvasesContainerRef.current?.removeEventListener('wheel', handleWheel)
      }
    }, [])

    const onCanvasContextMenu = (e) => e.preventDefault()

    return (
      <ContextMenu
        data={data}
        canvas={canvas}
        canvasActiveObject={canvasActiveObject}
        isShiftPressed={isShiftPressed}
        setActiveObjectModifier={setActiveObjectModifier}
        updateCanvas={updateCanvas}
        getSlideChangesOnPasteAvatar={getSlideChangesOnPasteAvatar}
        isTextEditingEntered={isTextEditingEntered}
      >
        <div style={style.marginTop20} onContextMenu={onCanvasContextMenu}>
          <div className="video-format-limiter left"></div>
          <div className="video-format-limiter right"></div>
          <div className="canvas" ref={canvasesContainerRef}>
            {video.slides.map((slide) => (
              <div
                key={slide.id}
                style={{
                  visibility: player.activePreview && preparingAnimations ? 'hidden' : 'visible',
                }}
              >
                <canvas id={'canvas-' + slide.id} style={style.positionAbsolute} />
              </div>
            ))}
            {(!player.canvasReady || (player.activePreview && preparingAnimations)) && (
              <Spin style={style.topLeftPostionZ} />
            )}
            {canvas && (player.activePreview || player.status === 'playing') && (
              <div className="canvas-alert-message">
                <Icon name="info" />
                <span className="message-text">The lip movement is not available in video preview</span>
              </div>
            )}
            <InteractivityEmulationControls
              interactiveQuestion={interactiveQuestion}
              interactiveElements={interactiveElements}
              player={player}
              setPlayer={setPlayer}
              playNextSlide={playNextSlide}
            />
          </div>
        </div>
      </ContextMenu>
    )
  },
)

export default Canvas
